import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Activities() {
  const [activities, setActivities] = useState([]);
  const username = localStorage.getItem('username');

  useEffect(() => {
    const fetchActivities = async () => {
      if (username) {
        try {
          const response = await axios.get(`https://api.kasirenew.com/api/subagen/activities/${username}`);
          const latestActivities = response.data.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          });
          setActivities(latestActivities);
        } catch (error) {
          console.error('Error fetching activities:', error);
        }
      }
    };

    fetchActivities();
  }, [username]);

  return (
      <div className="max-w-2xl bg-white shadow-md rounded-md p-6 mt-8">
        <h2 className="text-2xl font-semibold mb-4">Update terbaru</h2>
        <ul>
          {activities.length === 0 ? (
              <li className="text-gray-600 text-sm">No updates</li>
            ) : (
            activities.map((activity, index) => (
              <li
                key={index}
                className="bg-blue-100 p-2 rounded-lg mb-2 border-l-4 border-blue-500"
              >
                <div className="flex justify-between">
                  <span className="mr-2">{activity.info} dari link referral anda</span>
                  <span className="text-gray-600 text-sm">
                    {new Date(activity.created_at).toLocaleString('en-GB')}
                  </span>
                </div>
              </li>
            ))
          )}
        </ul>
      </div>
  );
}

export default Activities;
