import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../img/logo-purple.png';
import axios from 'axios';
import StickyNavbar from '../components/StickyNavbar';
import Sidebar from '../components/Sidebar';

function ChangePassword() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation logic for password and confirmPassword here
    if (formData.password !== formData.confirmPassword) {
      setErrors({ confirmPassword: ['Passwords do not match'] });
      return;
    }

    // Make a POST request to update the password
    axios
      .post('https://api.kasirenew.com/api/subagen/updatepassword', {
        email: localStorage.getItem('email'),
        password: formData.password,
      })
      .then((response) => {
        setSuccessMessage('Password updated successfully');
        setTimeout(() => {
          navigate('/');
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        // Handle any error messages here
      });
  };

  return (
    <div className="flex bg-gray-100 mb-14 md:mb-0">
      <Sidebar /> {/* Render the Sidebar component */}
      <div className="flex-grow md:px-8 md:py-5">
    {/* <div className="min-h-screen flex md:items-center md:justify-center bg-gray-100"> */}
      <div className="bg-white p-8 rounded-lg md:shadow-md md:w-96 w-full">
        <img src={logo} alt="Logo" className="mx-auto mb-8 h-8" />
        <h2 className="text-xl font-semibold mb-4 text-center">Change Password</h2>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
              New Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className={`w-full border rounded-md py-2 px-3 text-gray-700 ${
                errors.password ? 'border-red-500' : ''
              }`}
              placeholder="Enter your password"
              value={formData.password}
              onChange={handleInputChange}
            />
            {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password[0]}</p>}
          </div>
          <div className="mb-4">
            <label
              htmlFor="confirmPassword"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Confirm Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              className={`w-full border rounded-md py-2 px-3 text-gray-700 ${
                errors.confirmPassword ? 'border-red-500' : ''
              }`}
              placeholder="Confirm your password"
              value={formData.confirmPassword}
              onChange={handleInputChange}
            />
            {errors.confirmPassword && (
              <p className="text-red-500 text-sm mt-1">{errors.confirmPassword[0]}</p>
            )}
          </div>
          <button
            type="submit"
            className="w-full bg-purple-600 text-white font-semibold py-2 px-4 rounded-md hover:bg-purple-700"
          >
            Reset Password
          </button>
          {successMessage && (
            <p className="text-green-600 text-sm mt-3 text-center">{successMessage}</p>
          )}
        </form>
      </div>

      <StickyNavbar />
      </div>
    </div>
  );
}

export default ChangePassword;