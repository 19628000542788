import React, { useState } from 'react';
import logo from '../img/logo-purple.png';
import axios from 'axios';

function ForgotPassword() {

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [notify, setNotify] = useState('');
  
  const handleForgotPassword = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://api.kasirenew.com/api/subagen/forgotpassword', { email }); // Use relative URL

      localStorage.setItem('email', email);

      setNotify(response.data.message);
    } catch (error) {
      console.log(error.response.data.message);
      setError(error.response.data.message);
    }
  };

  return (
    <div className="min-h-screen flex md:items-center md:justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md md:w-96 w-full">
        <img src={logo} alt="Logo" className="mx-auto mb-8 h-8" />
        <h2 className="text-xl font-semibold mb-4 text-center">Forgot Password?</h2>
        <form onSubmit={handleForgotPassword}>
          {error && <p className="text-center text-red-500 mb-4">{error}</p>}
          <p className="text-center text-green-500 mb-4">{notify}</p>
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="w-full border rounded-md py-2 px-3 text-gray-700"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="w-full bg-purple-600 text-white font-semibold py-2 px-4 rounded-md hover:bg-purple-700"
          >
            Reset Password
          </button>
        </form>
        <div className="flex items-center justify-between mt-8">
          <a href="/register" className="text-purple-600 hover:underline">Daftar akaun</a>
          <span className="mx-2 text-gray-400">|</span>
          <a href="/login" className="text-purple-600 hover:underline">Log Masuk</a>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
