import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import logo from '../img/logo-purple.png';
import axios from 'axios'; // Import Axios for making API requests

function Registration() {
  const navigate = useNavigate(); // Initialize useNavigate for redirection

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    username: '',
    password: '',
    confirmPassword: '',
    bank: '',
    account: '',
    accountowner: '',
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Check if password matches confirmPassword
    if (formData.password !== formData.confirmPassword) {
      // Set an error message for password mismatch
      setErrors({ confirmPassword: ['Passwords do not match'] });
      return;
    }
  
    try {
      const response = await axios.post('https://api.kasirenew.com/api/subagen/register', formData);
      console.log(response); // 'Registration successful'
  
      // Reset form data and display success message
      setFormData({
        name: '',
        email: '',
        username: '',
        password: '',
        confirmPassword: '',
        bank: '',
        account: '',
        accountowner: '',
      });
      setErrors({});
      setSuccessMessage('Registration successful. You can now log in.');
      localStorage.setItem('email', formData.email);
  
      navigate('/verify');
    } catch (error) {
      if (error.response.status === 422) {
        // Validation error: Update the errors state with error messages
        setErrors(error.response.data.errors);
      } else {
        console.error('Registration failed:', error);
      }
    }
  };  

  return (
    <div className="min-h-screen flex md:items-center md:justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md md:w-96 w-full md:my-8">
        <img src={logo} alt="Logo" className="mx-auto mb-8 h-8" />
        <h2 className="text-xl font-semibold mb-4 text-center">Pendaftaran Sub Agen</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">
              Nama
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className={`w-full border rounded-md py-2 px-3 text-gray-700 ${
                errors.name ? 'border-red-500' : ''
              }`}
              placeholder="Masukkan nama anda"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
            {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name[0]}</p>}
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className={`w-full border rounded-md py-2 px-3 text-gray-700 ${
                errors.email ? 'border-red-500' : ''
              }`}
              placeholder="Masukkan email anda"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email[0]}</p>}
          </div>
          <div className="mb-4">
            <label htmlFor="username" className="block text-gray-700 text-sm font-bold mb-2">
              Username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              className={`w-full border rounded-md py-2 px-3 text-gray-700 ${
                errors.username ? 'border-red-500' : ''
              }`}
              placeholder="Masukkan username anda"
              value={formData.username}
              onChange={handleInputChange}
              required
            />
            {errors.username && <p className="text-red-500 text-sm mt-1">{errors.username[0]}</p>}
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className={`w-full border rounded-md py-2 px-3 text-gray-700 ${
                errors.password ? 'border-red-500' : ''
              }`}
              placeholder="Masukkan password anda"
              value={formData.password}
              onChange={handleInputChange}
              required
            />
            {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password[0]}</p>}
          </div>
          <div className="mb-4">
            <label
              htmlFor="confirmPassword"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Confirm Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              className={`w-full border rounded-md py-2 px-3 text-gray-700 ${
                errors.confirmPassword ? 'border-red-500' : ''
              }`}
              placeholder="Ulang password anda"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              required
            />
            {errors.confirmPassword && (
              <p className="text-red-500 text-sm mt-1">{errors.confirmPassword[0]}</p>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="bank" className="block text-gray-700 text-sm font-bold mb-2">
              Nama Bank
            </label>
            <input
              type="text"
              id="bank"
              name="bank"
              className={`w-full border rounded-md py-2 px-3 text-gray-700 ${
                errors.bank ? 'border-red-500' : ''
              }`}
              placeholder="Masukkan nama bank (Cth: Maybank)"
              value={formData.bank}
              onChange={handleInputChange}
              required
            />
            {errors.bank && <p className="text-red-500 text-sm mt-1">{errors.bank[0]}</p>}
          </div>
          <div className="mb-4">
            <label htmlFor="account" className="block text-gray-700 text-sm font-bold mb-2">
              No. Akaun Bank
            </label>
            <input
              type="text"
              id="account"
              name="account"
              className={`w-full border rounded-md py-2 px-3 text-gray-700 ${
                errors.account ? 'border-red-500' : ''
              }`}
              placeholder="Masukkan nombor akaun bank anda"
              value={formData.account}
              onChange={handleInputChange}
              required
            />
            {errors.account && <p className="text-red-500 text-sm mt-1">{errors.account[0]}</p>}
          </div>
          <div className="mb-4">
            <label htmlFor="accountowner" className="block text-gray-700 text-sm font-bold mb-2">
              Nama Pemilik Akaun
            </label>
            <input
              type="text"
              id="accountowner"
              name="accountowner"
              className={`w-full border rounded-md py-2 px-3 text-gray-700 ${
                errors.accountowner ? 'border-red-500' : ''
              }`}
              placeholder="Masukkan nama pemilik akaun"
              value={formData.accountowner}
              onChange={handleInputChange}
              required
            />
            {errors.accountowner && <p className="text-red-500 text-sm mt-1">{errors.accountowner[0]}</p>}
          </div>
          <button
            type="submit"
            className="w-full bg-purple-600 text-white font-semibold py-2 px-4 rounded-md hover:bg-purple-700"
          >
            Register
          </button>
          {successMessage && (
            <p className="text-green-600 text-sm mt-3 text-center">{successMessage}</p>
          )}
        </form>
        <div className="flex items-center justify-between mt-8">
          <a href="/login" className="text-purple-600 hover:underline">Log Masuk</a>
          <span className="mx-2 text-gray-400">|</span>
          <a href="/forgot" className="text-purple-600 hover:underline">Terlupa password?</a>
        </div>
      </div>
    </div>
  );
}

export default Registration;
