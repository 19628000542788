import React, { useState, useEffect } from 'react';
import { FaSpinner } from 'react-icons/fa';
import axios from 'axios';

function Statistics() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const username = localStorage.getItem('username');

  const fetchData = () => {
    axios
      .get(`https://api.kasirenew.com/api/commission/${username}`)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };
  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  return (
    <div className="max-w-2xl bg-white shadow-md rounded-md p-6 mt-8">
      {loading ? (
          <div className="flex items-center justify-center">
            <FaSpinner className="text-4xl text-purple-600 animate-spin" />
          </div>
        ) : (
          data && (
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
              <div className="w-full md:w-1/2 bg-purple-500 p-4 rounded-md">
                <h3 className="text-lg font-semibold text-white">Jumlah Mohon Quotation</h3>
                <p className="text-3xl font-bold text-white mt-2">{data.total_referral}</p>
              </div>
              <div className="w-full md:w-1/2 bg-purple-600 p-4 rounded-md">
                <h3 className="text-lg font-semibold text-white">Jumlah Komisen</h3>
                <p className="text-3xl font-bold text-white mt-2">RM{data.total_commission}</p>
              </div>
              <div className="w-full md:w-1/2 bg-purple-700 p-4 rounded-md">
                <h3 className="text-lg font-semibold text-white">Komisen Belum Dibayar</h3>
                <p className="text-3xl font-bold text-white mt-2">RM{data.unpaid_commission}</p>
              </div>
            </div>
          )
        )}
    </div>
  );
}

export default Statistics;
