import React from 'react';
import Sidebar from '../components/Sidebar'; // Import the Sidebar component
import Header from '../components/Header';
import ShowSettings from '../components/ShowSettings';
import StickyNavbar from '../components/StickyNavbar';

function Settings() {
  return (
    <div className="flex bg-gray-100 mb-14 md:mb-0">
      <Sidebar /> {/* Render the Sidebar component */}
      <div className="flex-grow px-8 py-5">
        <Header />
        {/* Settings content goes here */}
        <h1 className="text-2xl font-semibold">Settings</h1>
        <p className="text-gray-600">Welcome to the settings!</p>
        <ShowSettings />
      </div>
      <StickyNavbar />
    </div>
  );
}

export default Settings;
