import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaCog, FaKey, FaSignOutAlt } from 'react-icons/fa';

const StickyNavbar = () => {

  const logout = () => {
    localStorage.removeItem('user');
    window.location.reload();
  };

  return (
    <div className="fixed bottom-0 left-0 w-full bg-purple-600 p-4 md:hidden">
      <ul className="flex justify-between">
        <li className="m-2 text-white hover:text-primary">
          <Link to="/">
            <FaHome />
          </Link>
        </li>
        <li className="m-2 text-white hover:text-primary">
          <Link to="/settings">
            <FaCog />
          </Link>
        </li>
        <li className="m-2 text-white hover:text-primary">
          <Link to="/changepassword">
            <FaKey />
          </Link>
        </li>
        <li className="m-2 text-white hover:text-primary">
          <Link onClick={logout}>
            <FaSignOutAlt />
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default StickyNavbar;
