import React, { useEffect, useState } from 'react';
import clipboardCopy from 'clipboard-copy';

function ReferralURL() {
  const [referralURL, setReferralURL] = useState('');
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    // Retrieve the username from localStorage
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setReferralURL(`https://kasirenew.com/?ref=${storedUsername}`);
    }
  }, []);

  const handleCopyToClipboard = () => {
    clipboardCopy(referralURL);
    setIsCopied(true);
  };

  return (
    <div className="max-w-2xl bg-white shadow-md rounded-md p-6 mt-8">
      <h2 className="text-xl font-semibold mb-4">Copy link referral anda dibawah.</h2>
      <p>Kongsikan bersama keluarga dan kenalan anda dan jana komisen sebanyak <strong>5%</strong> untuk setiap kali mereka memperbaharui insuran kenderaan di KasiRenew.com.</p>
      <div className="mt-4">
        <form>
          <div className="mb-4 flex">
            <input
              type="text"
              id="referralURL"
              name="referralURL"
              className="w-full border rounded-md py-2 px-3 text-gray-700"
              value={referralURL}
              readOnly
            />
            <button
              type="button"
              className={`${
                isCopied
                  ? 'bg-green-500 text-white cursor-not-allowed'
                  : 'bg-blue-500 text-white hover:bg-blue-600'
              } font-semibold py-2 px-4 ml-2 rounded-md`}
              onClick={isCopied ? null : handleCopyToClipboard}
            >
              {isCopied ? 'Copied' : 'Copy'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ReferralURL;
