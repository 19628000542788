import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../img/logo-purple.png';
import axios from 'axios';

function ResetPassword() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [isValidToken, setIsValidToken] = useState(null);
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    // Get the email from localStorage
    const email = localStorage.getItem('email');

    // Make a POST request to verify the token
    axios
      .post('https://api.kasirenew.com/api/subagen/validateresettoken', { token, email })
      .then((response) => {
        setIsValidToken(true);
      })
      .catch((error) => {
        setIsValidToken(false);
      });
  }, [token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation logic for password and confirmPassword here
    if (formData.password !== formData.confirmPassword) {
      setErrors({ confirmPassword: ['Passwords do not match'] });
      return;
    }

    // Make a POST request to update the password
    axios
      .post('https://api.kasirenew.com/api/subagen/updatepassword', {
        email: localStorage.getItem('email'),
        password: formData.password,
      })
      .then((response) => {
        setSuccessMessage('Password updated successfully');
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
        // Handle any error messages here
      });
  };

  if (isValidToken === null) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen flex md:items-center md:justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md md:w-96 w-full">
        <img src={logo} alt="Logo" className="mx-auto mb-8 h-8" />
        <h2 className="text-xl font-semibold mb-4 text-center">Reset Password</h2>

        {isValidToken ? (
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                className={`w-full border rounded-md py-2 px-3 text-gray-700 ${
                  errors.password ? 'border-red-500' : ''
                }`}
                placeholder="Enter your password"
                value={formData.password}
                onChange={handleInputChange}
              />
              {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password[0]}</p>}
            </div>
            <div className="mb-4">
              <label
                htmlFor="confirmPassword"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Confirm Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                className={`w-full border rounded-md py-2 px-3 text-gray-700 ${
                  errors.confirmPassword ? 'border-red-500' : ''
                }`}
                placeholder="Confirm your password"
                value={formData.confirmPassword}
                onChange={handleInputChange}
              />
              {errors.confirmPassword && (
                <p className="text-red-500 text-sm mt-1">{errors.confirmPassword[0]}</p>
              )}
            </div>
            <button
              type="submit"
              className="w-full bg-purple-600 text-white font-semibold py-2 px-4 rounded-md hover:bg-purple-700"
            >
              Reset Password
            </button>
            {successMessage && (
              <p className="text-green-600 text-sm mt-3 text-center">{successMessage}</p>
            )}
          </form>
        ) : (
          <p className="text-red-500 text-sm mt-3 text-center">Invalid token or token expired</p>
        )}
      </div>
    </div>
  );
}

export default ResetPassword;