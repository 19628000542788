import React from 'react';
import { Link } from 'react-router-dom';
import { useSidebar } from '../context/SidebarContext';
import logo from '../img/logo.png'; // Import the logo image

function Sidebar() {
  const { isSidebarOpen } = useSidebar();
  const { toggleSidebar } = useSidebar();
  const logout = () => {
    localStorage.removeItem('user');
    window.location.reload();
  };

  return (
    <div className={`bg-purple-600 text-white h-screen md:block sticky top-0 md:w-64 w-full ${isSidebarOpen ? 'hidden' : 'block'}`}>
     {/* Logo */}
      <div className="p-4">
        <img src={logo} alt="Logo" className="h-6 md:h-8" />
      </div>

      {/* Other sidebar content */}
      <ul>
        <li>
          <Link to="/" className="block p-4 hover:bg-purple-500" onClick={toggleSidebar}>
            Dashboard
          </Link>
          <Link to="/settings" className="block p-4 hover:bg-purple-500" onClick={toggleSidebar}>
            Settings
          </Link>
          <Link to="/changepassword" className="block p-4 hover:bg-purple-500" onClick={toggleSidebar}>
            Change Password
          </Link>
          <Link className="block p-4 hover:bg-purple-500" onClick={logout}>
            Log Out
          </Link>
          {/* Add other navigation links */}
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
