import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ShowSettings = () => {
  const [formData, setFormData] = useState({
    name: '',
    username: '',
    email: '',
    bank: '',
    account: '',
    accountowner: '',
  });

  const [isLoading, setIsLoading] = useState(false); // Initialize loading state

  useEffect(() => {
    // Retrieve the email from localStorage
    const email = localStorage.getItem('email');

    if (email) {
      // Use the email as a reference to fetch data
      axios.get(`https://api.kasirenew.com/api/subagen/info/${email}`)
        .then((response) => {
          // Update the form data with the fetched data
          console.log(response.data);
          setFormData({
            id: response.data.id || '',
            name: response.data.name || '',
            username: response.data.username || '',
            email: response.data.email || '',
            bank: response.data.bank || '',
            account: response.data.account || '',
            accountowner: response.data.accountowner || '',
          });
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, []); // The empty dependency array ensures this effect runs only once

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Send updated formData to the API
    axios.put(`https://api.kasirenew.com/api/subagen/updateinfo/${formData.id}`, formData)
      .then((response) => {
        toast.success('Data updated successfully');
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error('Error updating data');
        setIsLoading(false);
      });
  };

  return (
    <div className="max-w-2xl mt-8">
      <ToastContainer />
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 py-10 mb-4 flex flex-wrap">
        <div className="w-full md:w-1/2 px-3 mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            Name
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            name="name"
            id="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleInputChange}
          />
        </div>
        <div className="w-full md:w-1/2 px-3 mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
            Username
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            name="username"
            id="username"
            placeholder="Username"
            value={formData.username}
            onChange={handleInputChange}
            disabled
          />
        </div>
        <div className="w-full md:w-1/2 px-3 mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleInputChange}
            disabled
          />
        </div>
        <div className="w-full md:w-1/2 px-3 mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bank">
            Bank
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            name="bank"
            id="bank"
            placeholder="Bank"
            value={formData.bank}
            onChange={handleInputChange}
          />
        </div>
        <div className="w-full md:w-1/2 px-3 mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="account">
            Account
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            name="account"
            id="account"
            placeholder="Account"
            value={formData.account}
            onChange={handleInputChange}
          />
        </div>
        <div className="w-full md:w-1/2 px-3 mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="accountowner">
            Account Owner
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            name="accountowner"
            id="accountowner"
            placeholder="Account Owner"
            value={formData.accountowner}
            onChange={handleInputChange}
          />
        </div>
        <div className="w-full px-3 mb-4">
          <button
            className={`bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            type="submit"
            disabled={isLoading} // Disable the button when loading
          >
            {isLoading ? (
              <svg className="animate-spin h-5 w-5 mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 8.003 0 0112 4.472v7.82l4.94 2.971-1.414 2.141A10.01 10.01 0 002 12.067h4z"
                ></path>
              </svg>
            ) : (
              'Save'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ShowSettings;
