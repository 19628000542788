import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

const Private = ({ Component }) => {
  const [data, setData] = useState(null); // Initialize as null, not [false]

  useEffect(() => {
    const checkToken = async () => {
      try {
        const token = localStorage.getItem('user');
        const email = localStorage.getItem('email');
        if (!token || !email) {
          setData(false); // Set data to false when token or email is missing
          return;
        }
  
        const response = await axios.get('https://api.kasirenew.com/api/subagen/verify', { 
          params: {
            email: email, // Use email without curly braces
            token: token, // Use token without curly braces
          },
        });
  
        // Token is valid; handle success
        console.log(response.data.verify);
        setData(response.data.verify);
      } catch (error) {
        // Token validation failed; handle error
        console.error('Token validation failed:', error);
        setData(false); // Set data to false on validation failure
      }
    };
  
    checkToken();
  }, []);  

  // Conditionally render the Component or navigate to /login based on data
  return data !== false ? <Component /> : <Navigate to="/login" />;
};

export default Private;
