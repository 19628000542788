import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function Header({ backTo }) {
  // const { toggleSidebar } = useSidebar();
  return (
    <header className="bg-gray-100 mb-8 sticky top-0 py-2">
      <div className="container flex justify-normal">
        {backTo && (
          <Link to={backTo} className="text-purple-600 text-xl hover:text-purple-700 mr-5">
            <FontAwesomeIcon icon={faArrowLeft} />
          </Link>
        )}
        {/* <button
          className="md:hidden text-purple-600 text-xl hover:text-purple-700 focus:outline-none"
          onClick={toggleSidebar}
        >
          ☰
        </button> */}
      </div>
    </header>
  );
}

export default Header;
