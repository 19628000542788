import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import Navigate
import { SidebarProvider } from './context/SidebarContext';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Registration from './pages/Registration';
import Private from './components/Private';
import Verify from './pages/Verify';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Settings from './pages/Settings';
import ChangePassword from './pages/ChangePassword';

function App() {

  return (
    <Router>
      <SidebarProvider>
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Registration />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/resetpassword/:token" element={<ResetPassword />} />

          {/* Private Routes */}
          <Route path="/" element={<Private Component={Dashboard} />} />
          <Route path="/settings" element={<Private Component={Settings} />} />
          <Route path="/changepassword" element={<Private Component={ChangePassword} />} />
        </Routes>
      </SidebarProvider>
    </Router>
  );
}

export default App;
