import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../img/logo-purple.png';
import axios from 'axios';

function Verify() {
  const navigate = useNavigate();

  const [otp, setOtp] = useState('');
  const email = localStorage.getItem('email');
  // const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  
  const handleVerify = async (e) => {
    e.preventDefault();

    try {
      console.log("email: ",email);
      console.log("otp ",otp);
      const response = await axios.post('https://api.kasirenew.com/api/subagen/verifyotp', { email, otp }); // Use relative URL

      console.log(response.data.message);
      
      navigate('/login');
    } catch (error) {
      console.error(error.response); // 'Invalid credentials'

      // Set the error message for display
      setError('Invalid OTP');
    }
  };

  return (
    <div className="min-h-screen flex md:items-center md:justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md md:w-96 w-full">
        <img src={logo} alt="Logo" className="mx-auto mb-8 h-8" />
        <h2 className="text-xl font-semibold mb-4 text-center">Masukkan 6 digit OTP yang telah dihantar ke email:</h2>
        <p className="text-center text-green-700 mb-4">{email}</p>
        <form onSubmit={handleVerify}>
          <div className="mb-4">
            <label htmlFor="otp" className="block text-gray-700 text-sm font-bold mb-2">
              OTP
            </label>
            <input
              type="text"
              id="otp"
              name="otp"
              className="w-full border rounded-md py-2 px-3 text-gray-700"
              placeholder="Enter your otp"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <button
            type="submit"
            className="w-full bg-purple-600 text-white font-semibold py-2 px-4 rounded-md hover:bg-purple-700"
          >
            Sahkan OTP
          </button>
        </form>
      </div>
    </div>
  );
}

export default Verify;
